


















import { Component, Prop, Vue } from 'vue-property-decorator';
import { ConservatorshipDetails } from '@/modules/conservatorship/types';

const NotesPlugin = () => import('@/modules/notes/views/List.vue');

@Component({
  components: { NotesPlugin }
})
export default class NotesTab extends Vue {
  @Prop({ type: Object }) conservatorship?: ConservatorshipDetails;
}
